.MemeList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 30px;
  max-width: 1504px;
  grid-row-gap: 30px;
  margin: 0 auto;
}

.MainPage {
  min-height: 100vh;

  background-image: 
  url('./gates.png'),
  linear-gradient(180deg, #5E6D5D 0%, #7F8C79 12.5%, #7F8C79 89.06%, #5E6D5D 100%),
  url('./bg-trees.png'),
  url('./bg-trees-right.png'),
  ;
  background-blend-mode: multiply;
  background-position: 
    top, 
    center, 
    left 360px,
    right 360px
  ;
  background-repeat: 
    no-repeat,
    no-repeat,
    repeat-y,
    repeat-y,
  ;
  // background: linear-gradient(180deg, #5E6D5D 0%, #64810D 30.21%, #252E24 57.29%, #839A40 79.69%, #7F8B7B 100%);
  &-ContentWrapper {
    padding: 200px 17% 0;
    
    background-repeat: repeat-y;
    
  }
}