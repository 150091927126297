/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;

  left: -10px;
  position: relative;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Modal Header */
.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

/* Modal Body */
.modal-body {
  padding: 2px 16px;
}

/* Modal Footer */
.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

/* Modal Content */
.modal-content {
  position: relative;
  margin: auto;
  padding: 0;

  animation-name: animatetop;
  animation-duration: 0.4s;
  width: 80%;
  max-width: 895px;

  background: linear-gradient(180deg, #7f9485 0%, #525e50 100%);
  mix-blend-mode: normal;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(100px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;

  opacity: 0.9;
  top: 50%;
  transform: translateY(-50%);
}

.pepe-image {
  opacity: 0.5;
  position: relative;
  width: 273px;
  img {
    width: 100%;
    height: auto;
  }
}

.pay-modal-body {
  padding: 2px 16px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 20px;
  gap: 0px 10px;
  grid-template-areas:
    "pepe-image controls paybuttons"
    "pepe-image controls paybuttons"
    "pepe-image disclaimer disclaimer";
}
.pepe-image {
  grid-area: pepe-image;
}
.pepe-controls {
  grid-area: controls;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
.pepe-disclaimer {
  grid-area: disclaimer;
}
.pepe-paybuttons {
  grid-area: paybuttons;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.pay-button {
  width: 82px;
  height: 82px;
  border-radius: 50%;
  background: #797979;
  border: 2px solid #686868;
  box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.25);
  margin-left: 10px;
  color: #fbff20;

  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 80px;
  text-align: center;

  cursor: pointer;

  &:hover {
    background: #3f3f3f;
  }

  &:active {
    background: #5e5c5c;
  }
}

/* Add Animation */
@keyframes animatetop {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 0.9;
  }
}

.pay-price-input {
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #c4c4c4;
  border-radius: 20px;
  text-align: center;
  line-height: 80px;

  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  text-align: center;

  &::before {
    content: "$";
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 33.4478px;
    line-height: 44px;
    text-align: center;

    color: #fbff20;
  }
}

.pay-modal-select {
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #c4c4c4;
  border-radius: 20px;
  text-align: center;
  line-height: 80px;

  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  text-align: center;

  max-width: 179px;
}
