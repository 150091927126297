.Order {
  position: absolute;
  right: 5%;
  top: 15%;
}

.Order-Text {
  font-size: 36px;
  font-weight: bold;
}

.Order-Selector {
  background: #979797;
  border: 2px solid #666666;
  box-sizing: border-box;
  border-radius: 15px;
  font-size: 21px;
  padding: 10px 20px;
  ul {
    margin: 0;
    list-style: none;
    padding: 0;
    text-align: left;

    li {
      line-height: 40px;
      cursor: pointer;
      &:hover, &.active {
        color: white;
      }
    }
  }
}