.MemePage {
  height: calc(100vh - 85px);
  background-image:
  url('./assets/grass.png'),
  url('./assets/image\ 80\ \(2\).png'),
  linear-gradient(180deg, #AEC2C2 19.79%, #2C3C29 100%, #50554F 100%),
  ;
  // background-blend-mode: multiply;
  background-position:
  bottom, 
  center bottom 110px,
  center, 
  ;
  background-size: 100vw 20vh, 100vw 50vh, 100vw auto
  ;
  background-repeat: no-repeat;


  &-Direction {
    margin: 0!important;
    position: absolute;

    left: 50%;
    transform: translateX(-50%);
    animation: MoveUpDown 1s ease-in-out infinite;
    width: 100%;
    text-align: center;

    font-weight: bold;
    color: #FFFFFF;
    font-size: 2.84vh;
    z-index: 5;
    span {
      color: #FBFF20;
    }

    @keyframes MoveUpDown {
      0%, 100% {
        top: 16vh;
      }
      50% {
        top: 17vh;
      }
    }
  }
}