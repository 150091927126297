.MemeHeadstone {
  display: block;
  position: absolute;
  bottom: 10.5vh;
  left: 50%;
  transform: translateX(-50%);
  &-Body {
    height: 62.03vh;//0,818027211
    width: 50.74vh;
    background-image: url("./assets/headstone.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    position: relative;
    margin: 0 auto;
  }

  &-Image {
    position: absolute;
    top: 18%;
    left: 50%;
    transform: translateX(-50%);
    width: 75%;

    img {
      width: 100%;
      height: auto;
    }
  }

  &-Title {
    margin: 0;
    height: 8.44vh;
    font-size: 1.69vh;
    line-height: 7.4vh;
    background: #bdbdbd;
    border: 2px solid #666666;
    box-sizing: border-box;
    border-radius: 1.6vh;
    width: 75%;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20%;
    span {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
    }
  }

  &-Respects {
    text-align: center;
    position: absolute;
    width: 43%;
    left: 50%;
    font-size: 1.69vh;
    bottom: 1%;
    transform: translateX(-50%);
    background: #bdbdbd;
    border: 2px solid #666666;
    box-sizing: border-box;
    line-height: 3.16vh;
    border-radius: 1.6vh;
  }

  &-Respect-Animated {
    text-align: center;
    position: absolute;
    width: 35%;
    left: 50%;
    bottom: 50%;
    transform: translateX(-50%);

    box-sizing: border-box;
    line-height: 30px;
    opacity: 0;
    width: 38.4vh;
    img {
      width: 100%;
      height: auto;
    }

    p {
      margin: 0!important;
      background: linear-gradient(180deg, #FFFFFF 0%, #5F5F5F 100%);
      border: 3px solid #757777;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 5px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0.5vh;
      font-size: 1.69vh;
      line-height: 1.70vh;
      padding: 1vh;
    }
    &.active {
      animation: RiseUpAndQuit 3s ease-out normal;
      z-index: 999;
      font-size: 2.43vh;
      font-weight: bold;
      color: white;
    }

    @keyframes RiseUpAndQuit {
      0% {
        bottom: 30%;
        opacity: 1;
      }
      100% {
        bottom: 65%;
        opacity: 0;
      }
    }
  }

  &-RespectsButton {
    color: #fbff20;
    border: 2.25px solid #686868;
    box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    width: 4.2vh;
    height: 4.2vh;

    position: absolute;
    bottom: 8.5%;
    left: 50%;
    transform: translateX(-50%);

    text-align: center;
    font-size: 2.85vh;
    line-height: 4vh;
    font-family: "Roboto";
    font-weight: 700;
    background: #797979;
    cursor: pointer;

    &:hover {
      background: #646464;
    }

    &:active {
      background: #585252;
    }
  }
}
