.AboutPage {
  background: linear-gradient(180deg, #84998A 0%, #586556 100%);
  text-align: center;

  &-Container {
    max-width: 1280px;
    margin: 0 auto;
    padding-bottom: 48px;
  }

  &-Welcome {
    padding-top: 80px;
    h1 {
      color : #222222;
      font-size: 72px;
      font-weight: 800;
      margin: 0;
      font-family: 'Lato', sans-serif;
      margin: 20px 0;
    }
    h2 {
      font-size: 48px;
      margin: 20px 0;
      opacity: 0.6;
      margin: 0;
      font-style: normal;
      font-weight: normal;
    }
  }

  &-TombIcon { 
    margin: 30px 0;
  }

  &-Description {
    padding: 48px 15px;
    background-image: url('./assets/bg.png');
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 24px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    p {
      margin: 20px 0;
    }
    &-About {
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 48px!important;
    }
  }
}