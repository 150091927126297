.header {
  background: #979797;
  padding: 12px  75px;
  display: flex;

  a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
  } 

  .link {
    display: flex;
    align-items: center;
    margin: 0 25px;

    &__text {
      margin-left: 15px;
    }
  }
}