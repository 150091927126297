.MemeListItem {
  width: 301px;
  display: block;
  &-Body {
    background-image: url('./headstone.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 320px;
    position: relative;
  }

  &-Image {
    position: absolute;
    top: 18%;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &-Title {
    margin: 0;
    height: 80px;
    line-height: 70px;
    background: #BDBDBD;
    border: 2px solid #666666;
    box-sizing: border-box;
    border-radius: 15px;

    text-align: center;

    span {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
    }
  }

  &-Respects {
    position: absolute;
    bottom: 18px;
    width: 84%;
    left: 50%;
    transform: translateX(-50%);
    background: #BDBDBD;
    border: 2px solid #666666;
    box-sizing: border-box;
    border-radius: 15px;
    text-align: center;
  }
}